<script setup>
const {$activeModalsBus} = useNuxtApp();
const show = ref(false);
const html = ref(null);
$activeModalsBus.listen("openCarSvg", (node) => {
  html.value = node.outerHTML;
  show.value = true;
});
$activeModalsBus.listen("closeCarSvg", () => {
  show.value = false;
});
</script>
<template>
  <v-dialog v-model="show" max-width="400">
    <a class="modal-close" href="javascript:void(0)" @click="$activeModalsBus.event('closeCarSvg')" aria-label="Close">
      <nuxt-img width="30px" src="/images/icons/close.png" alt="אייקון של X לסימון סגירה חלון באתר"/>
    </a>
    <v-card>
      <div class="modal-content py-3">
        <div class="product-modal-svg product-svg">
          <span v-html="html"></span>
          <div class="text-center"><b>100%</b> התאמה לדגם שלך</div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.product-modal-svg {
  svg {
    margin: 0 auto;
  }

  &.product-svg {
    max-height: none;
  }
}

.modal-close {
  transform: translate(10%, 0%);
  @media screen and (max-width: 800px) {
    transform: translate(30%, 0%);
  }
}
</style>
